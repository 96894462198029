<template>
  <v-container fluid class="mt-0 pt-0 pl-4" >
    <v-tabs @change="selectedTab()" v-model="tab">
      <v-tab href="#AuotmaticPublications" >
        <v-icon left> fa-solid fa-rotate </v-icon>
        {{
          $t(
            "components.views.settings.posting_rules.automatic_publications.automatic_publications"
          )
        }}
      </v-tab>
      <v-tab href="#PostRadius">
        <v-icon left> fa-solid fa-street-view </v-icon>
        {{ $t("components.views.settings.posting_rules.post_radius.publication_radius") }}
      </v-tab>
      <v-tab-item value="AuotmaticPublications" v-if="tab === 'AuotmaticPublications'" >
        <AutomaticPublications :key="key" />
      </v-tab-item>
      <v-tab-item value="PostRadius" v-if="tab === 'PostRadius'" >
        <PostRadius :key="key" />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    PostRadius: () =>
      import(
        /* webpackChunkName: "PostRadius" */ "@/components/views/Settings/PostingRules/PostRadius/PostRadius.vue"
      ),
    AutomaticPublications: () =>
      import(
        /* webpackChunkName: "AutomaticPublications" */ "@/components/views/Settings/PostingRules/AutomaticPublications/AutomaticPublications.vue"
      ),
  },
  data: () => ({
    activeTrip: true,
    tab: "DriverVehicle",
    key: 1,
  }),
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
    username() {
      if (this.user !== undefined) {
        let userMap = JSON.parse(this.user);
        return userMap.user.username;
      }
    },
    desTypeUser() {
      if (this.user !== undefined) {
        let userMap = JSON.parse(this.user);
        return userMap.user.desTypeUser;
      }
    },
    typeUser() {
      if (this.user !== undefined) {
        let userMap = JSON.parse(this.user);
        return userMap.user.typeUser;
      }
    },
    activeSide() {
      return this.active;
    },
  },
  methods: {
    ...mapActions("loader", ["showLoader"]),

    selectedTab() {
      this.key += 1;
    },
  },
};
</script>
